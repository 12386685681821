import React from "react"
import { ArticleView } from "../modules/ArticleView"
import { Router as MyRouter } from "@reach/router"
import { graphql } from "gatsby"
import Layout from "../components/layout"

const Router = ({ data }) => {
  const {
    strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data
  return (
    <Layout
      pageContext={{
        locale: defaultLocale,
        locales,
        defaultLocale,
        slug: "404",
      }}
      global={strapiGlobal}
    >
      <MyRouter>
        <ArticleView path="/app/:slug" />
      </MyRouter>
    </Layout>
  )
}

export default Router
export const query = graphql`
  query NotFoundQuer7 {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapiGlobal {
      ...GlobalData
    }
  }
`
