import axios from "axios"
import React, { useEffect, useState } from "react"
import Image from "../components/image"
import Markdown from "react-markdown"
import { format } from "date-fns"
import { Helmet } from "react-helmet"
import ReactMarkdown from "react-markdown"






export const ArticleView = ({ slug }) => {
  const [person, setPerson] = useState()
  useEffect(() => {
    axios.get(`https://cms.evplugs.co.in/blogs?slug=${slug}`)
  }, [])

  return <ArticleRender articleSlug={slug}></ArticleRender>
}
class ArticleRender extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      slug: props.articleSlug,
      next: null,
    }
  }

  componentDidMount() {
    // axios
    //   .get(`https://cms.evplugs.co.in/blogs?slug=${this.state.slug}`)
    //   .then(x => {
    //     console.log("x", x.data[0])
    //     setState({ data: x.data[0] })
    //   })
    const endpoint = `https://cms.evplugs.co.in/blogs?slug=${this.state.slug}`
    const setState = this.setState.bind(this)
    fetch(endpoint, {
      method: "GET",
    })
      .then(resp => resp.json())
      .then(function (response) {
        setState({ data: response[0] })
      })
      .then(() => {
        const endpoint = `https://cms.evplugs.co.in/blogs?id=${
          this.state.data.id + 1
        }`
        const setState = this.setState.bind(this)
        fetch(endpoint, {
          method: "GET",
        })
          .then(resp => resp.json())
          .then(function (response) {
            setState({ next: response[0] })
          })
      })
  }

  render() {
    return (
      <div>
        {this.state.data === null ? (
          <div className="container">
            <div class="sk-chase m-auto">
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
            </div>
          </div>
        ) : (
          <div className="container">
            <Helmet>
              <title>{this.state.data?.metaData?.metaTitle}</title>
              <meta
                name="description"
                content={this.state.data?.metaData?.metaDescription}
              />
              <meta
                name="twitterCardType"
                content={this.state.data?.metaData?.twitterCardType}
              />
              <meta
                name="twitterUsername"
                content={this.state.data?.metaData?.twitterUsername}
              />
              <meta
                name="og:description"
                content={this.state.data?.metaData?.metaDescription}
              />
              <meta
                name="twitter:description"
                content={this.state.data?.metaData?.metaDescription}
              />
            </Helmet>

            <div className="img-blog">
              <Image className="blog-image2" media={this.state.data.heroImage} />
            </div>
            <span className="header-label">{this.state.data.title}</span>
            <hr
              className="my-3 md:my-3 hr hr-article"
              style={{
                borderTop: "8px solid var(--evp-primary)",
                width: "46px",
              }}
            ></hr>
            <div className="sub-heading-container">
              <div className="sub-heading">
                <span>{this.state.data.author}</span>
                <span>
                  {format(
                    new Date(this.state.data.created_at),
                    "MMMM do, yyyy"
                  )}
                </span>
              </div>
              <div className="icons">
                <span></span>
              </div>
            </div>
            <div className="article-final">
              <div>
              <div dangerouslySetInnerHTML={{__html: this.state.data.content}} />
                {/* {this.state.data.content}
                <Markdown source={this.state.data.content} /> */}
              </div>
              {this.state.next ? (
                <a href={`/app/${this.state.next.slug}`}>
                  <div className="article-final-ads">
                    <span>Read Next</span>
                    <img src={this.state.next.image.url}></img>
                    <span>{this.state.next.title}</span>
                  </div>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
